export const keys = {
    // auth, instance
    user: 'user',
    authEncrypted: 'auth-encrypted',
    
    // rxdb
    userPassHashKey: 'rxdb-encryption-user-pass-hash',
    userPassHashFromHashKey: 'rxdb-encryption-user-pass-hash-from-hash',
    passwordEncryptedKey: 'rxdb-encryption-rxdb-password-encrypted',
    
    userPassHashKey2: 'rxdb-encryption-user-pass-hash-2',
    userPassHashFromHashKey2: 'rxdb-encryption-user-pass-hash-from-hash-2',
    passwordEncryptedKey2: 'rxdb-encryption-rxdb-password-encrypted-2',
    
    repoCommitHashKey: 'VUE_APP_COMMIT_HASH',
    
    cachedParams: 'calculated-params',
    pendingReports: 'pending-reports',
    lastSync: 'database-last-sync',
    
    encryptKey: 'DB_ENCRYPTION_ENABLED',
    logs: 'logs',
    dbLockKey: 'RxDB-db-remove-lock',
    
    trayChangesFlag: 'tray-changes-flag',
    
    keyPendingSync: 'rxdb-pending-sync-time',
    keyLastFullSync: 'rxdb-last-full-sync-time',
}

export const clearCachedData = function () {
    console.debug(`[clearCachedData] usuwanie (mozliwych do wygenerowania przez gui) danych z cache. Klucz=${keys.cachedParams}`)
    localStorage.removeItem(keys.cachedParams)
}

export default keys